@import "nlib/config";

.activity {
  display: flex;
  flex: auto;
  flex-direction: column;
  .addComment {
    padding-bottom: 0;
    border-bottom: none !important;
  }
  .addCommentField {
    textarea {
      min-height: 5.5rem !important;
      padding-left: $contentDefaultIndent;
    }
  }
  .viewAll {
    margin-top: $contentDefaultIndent * 2;
    margin-bottom: $contentDefaultIndent;
  }
  .preloader {
    flex: auto;
    margin: $contentDefaultIndent * 2 0;
  }
}
