@import "nlib/config";

.activity {
  padding: $contentDefaultIndent 0 $contentDefaultIndent;
  + .activity {
    margin-top: $contentDefaultIndent;
  }
  .head {
    display: flex;
    .avatarWrap {
      margin-right: $contentDefaultIndent;
      .avatar {
        font-size: 0.6875rem;
      }
    }
    .info {
      flex: 1;
    }
  }
  .content {
    margin-top: $contentDefaultIndent / 2;
    font-size: 0.8125rem;
    line-height: 1.4;
    word-break: break-word;
  }
}
