@import "nlib/config";

.event {
  .highlight {
    background-color: lighten($uiHighlightColor, 54%);
    color: $uiHighlightColor;
  }
  .special {
    background-color: lighten($uiSpecialColor, 34%);
    color: $uiSpecialColor;
  }
  .positive {
    background-color: lighten($uiPositiveColor, 58%);
    color: $uiPositiveColor;
  }
  .negative {
    background-color: lighten($uiNegativeColor, 38%);
    color: $uiNegativeColor;
  }
  .warning {
    background-color: lighten($uiWarningColor, 36%);
    color: $uiWarningColor;
  }
  .dark {
    background-color: lighten($uiDarkColor, 52%);
    color: $uiDarkColor;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    font-size: 1.5rem;
  }
  .info {
    .title {
      font-weight: 500;
      line-height: 1;
    }
    .meta {
      margin-top: $contentDefaultIndent / 2;
      overflow: hidden;
      font-size: 0.6875rem;
      line-height: 1;
      .date {
        color: $uiMediumDarkColor;
      }
    }
  }
  .contentWrapper {
    margin-top: $contentDefaultIndent;
    margin-left: 15px;
    padding-left: 10px;
    background: none !important;
    box-shadow: inset 2px 0 0 0;
  }
}
