@import "nlib/config";

.sideBar {
  display: flex;
  flex: 3;
  flex-direction: column;
  width: 25vw;
  min-width: 22rem;
  max-width: 30rem;
  overflow: auto;
  background-color: $uiWhiteColor;
  box-shadow: -2px 0 8px rgba($uiBlackColor, 0.05);
}
[data-mobile]:not([data-old-ui]) {
  .sideBar {
    flex: auto;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    overflow: auto;
    background-color: $uiWhiteColor;
    box-shadow: none;
  }
}
