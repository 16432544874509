@import "nlib/config";

.comment {
  &.typeNote,
  &.notRead {
    margin-right: -$contentDefaultIndent;
    margin-left: -$contentDefaultIndent;
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    border-radius: $contentDefaultIndent;
  }
  &.typeNote {
    background-color: lighten($uiWarningColor, 37);
  }
  &.notRead {
    animation: newComment 8s ease-in-out forwards;
  }
  .date {
    margin-top: $contentDefaultIndent / 2;
    color: $uiMediumDarkColor;
    font-size: 0.6875rem;
    line-height: 1;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 1rem;
    font-size: 0.8125rem;
    line-height: 1;
    span[data-mention] {
      margin-right: $contentDefaultIndent;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $uiPrimaryColor;
      }
    }
    .badge {
      min-width: fit-content;
    }
  }
  .mention {
    color: $uiPrimaryColor;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .text {
    padding-left: $contentDefaultIndent * 5;
    white-space: pre-wrap;
  }
}

@keyframes newComment {
  0% {
    background-color: lighten($uiPrimaryColor, 50);
  }
}
