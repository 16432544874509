@import "nlib/config";
@import "nlib/common/common.module";

.sideBarHeader {
  position: sticky;
  z-index: 10;
  top: 0;
  flex: none;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $contentDefaultIndent * 2;
    background-image: linear-gradient(to top, transparent, $uiWhiteColor 8px);
    .title {
      margin: 0;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
[data-mobile] {
  .sideBarHeader {
    .content {
      @extend %mobileContainer;
    }
  }
}
