@import "nlib/config";
@import "nlib/common/common.module";

.sideBarFooter {
  display: flex;
  position: sticky;
  z-index: 10;
  bottom: 0;
  margin-top: auto;
  padding: $contentDefaultIndent * 2;
  background-image: linear-gradient(transparent, $uiWhiteColor 8px);
  button {
    flex: 1;
    width: 1px;
    + button {
      margin-left: $contentDefaultIndent * 2;
    }
  }
}

[data-mobile] {
  .sideBarFooter {
    @extend %mobileContainer;
  }
}
