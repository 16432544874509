@import "nlib/config";

.changedFieldsList {
  color: $uiFontColor;
  font-size: 0.75rem;
  word-break: break-all;
  a {
    color: $uiDarkColor;
    text-decoration: underline;
    &:hover {
      color: $uiPrimaryColor;
      text-decoration: none;
    }
  }
  .listItem {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    > svg {
      flex: none;
      font-size: 0.75rem;
    }
    .fieldName {
      font-weight: 500;
    }
    .oldValue {
      opacity: 0.5;
    }
    .deletedValue {
      text-decoration: line-through;
    }
  }
}
