@import "nlib/config";

.addCommentField {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  .container {
    position: relative;
    width: 100%;
  }
  .autoComplete {
    textarea {
      min-height: 3rem;
      padding: $contentDefaultIndent / 2 $contentDefaultIndent;
      resize: none;
    }
    &.withTabs {
      textarea {
        border-top-left-radius: 0;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: $contentDefaultIndent;
    button {
      height: 1.75rem;
      padding-right: $contentDefaultIndent;
      padding-left: $contentDefaultIndent;
    }
  }
  &.outerButtons {
    .actions {
      button {
        height: 1.75rem;
        + button {
          margin-left: 4px;
        }
      }
    }
  }
  .relative {
    position: relative;
  }
  &:not(.outerButtons) {
    .actions {
      position: absolute;
      top: 50%;
      right: $contentDefaultIndent * 1.5;
      padding: 0;
      transform: translate(0, -50%);
      button {
        background-color: transparent;
      }
      &.compact {
        right: $contentDefaultIndent / 2;
      }
    }
    textarea {
      padding-right: 2.5rem;
    }
    input {
      padding-right: 2rem;
    }
  }
  .typeSelector {
    display: flex;
    position: relative;
    z-index: 1;
    width: fit-content;
    margin-bottom: -1px;
    .type {
      min-width: 44px;
      padding: $contentDefaultIndent / 2 $contentDefaultIndent;
      border: 1px solid transparent;
      border-bottom: none;
      border-radius: $uiBorderRadius $uiBorderRadius 0 0;
      color: lighten($uiFontColor, 40);
      line-height: 1;
      text-align: center;
      cursor: pointer;
      &:hover:not(.active) {
        color: $uiFontColor;
      }
      &.active {
        border-color: $uiBorderColor;
        background: $uiWhiteColor;
        color: $uiFontColor;
        font-weight: 500;
        &[data-type=note] {
          background-color: lighten($uiWarningColor, 37);
        }
      }
    }
    &.focused {
      .active {
        border-color: $uiPrimaryColor;
      }
    }
  }
  &.typeNote {
    input,
    textarea {
      background-color: lighten($uiWarningColor, 37);
    }
  }
  .sendButton {
    &.iconOnly {
      width: 28px;
      padding: 0;
      background-color: transparent !important;
      box-shadow: none !important;
      svg {
        color: $uiPrimaryColor;
        font-size: 1rem;
      }
      svg + svg {
        display: none;
      }
      &:hover,
      &[disabled] {
        svg {
          display: none;
          + svg {
            display: block;
          }
        }
      }
    }
  }
}
[data-mobile] {
  .addCommentField {
    .typeSelector {
      margin-bottom: -2px;
      .type {
        border-width: 2px;
      }
    }
    .autoComplete {
      textarea {
        min-height: 5rem;
      }
    }
  }
}
