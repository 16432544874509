@import "nlib/config";
@import "nlib/common/common.module";

.sideBarContent {
  display: flex;
  position: relative;
  flex: auto;
  flex-direction: column;
  padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
}

[data-mobile] {
  .sideBarContent {
    @extend %mobileContainer;
  }
}
