@import "nlib/config";

.fileTypedIcon {
  display: inline-block;
  font-size: 1.5rem;
  svg {
    font-size: 1em;
  }
  &:hover {
    background-color: rgba($uiBorderColor, 0.1);
  }
  &[data-extension=pdf] {
    svg {
      color: $uiNegativeColor;
    }
  }
  &[data-extension=doc],
  &[data-extension=docx] {
    svg {
      color: $uiPrimaryColor;
    }
  }
  &[data-extension=gif],
  &[data-extension=png],
  &[data-extension=jpg],
  &[data-extension=jpeg] {
    svg {
      color: $uiAlternativeColor;
    }
  }
  &[data-extension=xls],
  &[data-extension=xlsx] {
    svg {
      color: $uiPositiveColor;
    }
  }
}
